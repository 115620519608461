const {getCookieValueByName, removeCookieValue} = require('../utils/cookie');
const axios = require('axios');
import {performSearch }from '~/utils/searchUtil';
let isCartLoaded = false; //makes sure that the cart is only loaded once.

export default async ({store}) => {
  try{
    const url = window.location.href;
    const urlObj = new URL(url);
    const queryParams = new URLSearchParams(urlObj.search);

    const existingSessionCartItems = sessionStorage.cartItems && sessionStorage.cartItems != "{}" ? JSON.parse(sessionStorage.cartItems) : null;
    let cartId = getCookieValueByName("cart_id");

    if(queryParams.has('cart_id') && !isCartLoaded){
      isCartLoaded = true;
      sessionStorage.cartItems = "{}";
      store.dispatch("lineItems/CLEAR_CART_ITEMS");

      let cartid = queryParams.get('cart_id');
      let res = await axios.get(`https://admin.callia.com/checkout-automation/basket/cart_id=${cartid}`);

      let cartData = JSON.parse(res.data.carts);

      for(const cart of cartData){
        await parseCart(cart, store)
      }
      sessionStorage.cartItems = JSON.stringify(store.state.lineItems.cartItems);
    }
    else if(cartId && !existingSessionCartItems){
      let resp = await axios.get(`https://shop.callia.com/cart?sessionId=${cartId}`);
      if(resp.status == 200 && resp.data.cart && resp.data.cart.length > 0 && resp.data.cart != "{}"){
        let cartObj = JSON.parse(resp.data.cart);
        await store.dispatch("lineItems/FILL_CART_ITEMS", cartObj);
        sessionStorage.cartItems = JSON.stringify(store.state.lineItems.cartItems);
      }
      else{
        removeCookieValue("cart_id"); //cart cache expired or was deleted.
      }
    }
  }
  catch(err){
    console.error(err);
  }
};

const findProdType = (tags) => {
  if(tags.includes("bouquet")){
    return "Flowers"
  }
  else if (tags.includes("Bundle")){
    return "Bundle"
  }
  else if(tags.includes("curated_bundle")){
    return "Curated_Bundle"
  }
  else{
    return "Flowers"
  }
}
const parseCart = async (cart, store) => {
  let searchRes = await performSearch(cart.city);

  let cartLineItem = {
    product: {
      availableForSale: cart.bouquetSize.parentProd.availableForSale,
      bqSizeTag: cart.bouquetSize.value,
      displayTitle: cart.bouquetSize.parentProd.title,
      id: cart.bouquetSize.id,
      image:{
        id: cart.bouquetSize.variantShopifyProd.node.image.id,
        imageURL: cart.bouquetSize.variantShopifyProd.node.image.originalSrc
      },
      tags: cart.bouquetSize.parentProd.tags,
      parentProductId: cart.bouquetSize.parentProd.id,
      parentProductTitle: cart.bouquetSize.parentProd.title,
      verif_sku: cart.bouquetSize.sku,
      price: parseFloat(cart.bouquetSize.price),
      "itemPrice": `$${cart.bouquetSize.price}`,
      productType:{
        key: findProdType(cart.bouquetSize.parentProd.tags)
      }
    },
    recipient: {
      firstName: cart.firstName,
      lastName: cart.lastName,
      address: cart.address,
      city: cart.city,
      postalCode: cart.postalCode,
      deliveryNote: cart.deliveryNotes,
      apartment: cart.apartmentNumber,
      buzzerCode: cart.buzzerCode,
      relationship: cart.relationship,
      province: cart.province,
      addressType: cart.addressType,
    },
    deliveryWindow: {
      deliveryDate: cart.deliveryDateNew,
      deliveryRegion: cart.regionCode.toUpperCase(),
      price: cart.deliveryRate.price,
      productVariant: {
        id: cart.deliveryRate.id,
        sku: cart.deliveryRate.sku,
        availableForSale: true,
        price: {
          amount: cart.deliveryRate.price.replace("$", ""),
          product:  {
            productType: "Delivery",
            id: "gid://shopify/Product/334861860877",
            title: "Delivery Charge"
          }
        }
      },
      regionCode: cart.regionCode.toUpperCase(),
      timeView: cart.deliveryRate.value,
      title: cart.deliveryRate.value,
    },
    contactDetails: {
      name: cart.cName,
      phone: cart.phoneNumber,
      textCommunicationLang: "English",
      toggleYes: cart.consent
    },
    card: {
      id: cart.cardType.id,
      title: cart.cardType.value,
      availableForSale: true,
      tags: cart.cardType.parentProd.tags,
      images:[
        {
          id: cart.cardType.variantShopifyProd.image.id,
          imageURL: cart.cardType.variantShopifyProd.image.originalSrc
        }
      ],
      productVariants: [
        {
          id: cart.cardType.variantShopifyProd.id,
          sku: cart.cardType.sku,
          availableForSale: true,
          product:{
            productType: "Card",
            id: cart.cardType.parentProd.id,
            title: cart.cardType.parentProd.title
          },
          price: {
            amount: cart.cardType.price
          },

        }
      ]
    },
    cardMessages: {Message: cart.message},
    locale: cart.lang,
    citySearchResult:[],
    deliveryTown: searchRes[0],
    deliveryDate: cart.deliveryDateNew,
    occasion: cart.occasion,
    relationship: cart.relationship
  };
  store.dispatch("cartItem/PRODUCT_SET", {product: cartLineItem.product, locale: cartLineItem.locale, deliveryTown: cartLineItem.deliveryTown});

  if(cart.addons.length > 0){
    for(let i =0; i < cart.addons.length; i++){
      store.dispatch("cartItem/ADDON_UPDATE", {"title": cart.addons[i].value.value, "productType": "Gift-adds", "quantity": 1, "index": i, addon: {id: cart.addons[i].value.id, price: cart.addons[i].value.price, priceView: cart.addons[i].value.price, sku: cart.addons[i].value.variantShopifyProd.sku, tags:  cart.addons[i].value.parentProd.tags, title: cart.addons[i].value.parentProd.title, titleView: cart.addons[i].value.parentProd.title}});
    }
  }
  if(cart.vase.length > 0){
    store.dispatch("cartItem/VASE_ADD", {"title": cart.vase.value, "productType": "Vase", "quantity": 1, "index": 1, addon: {id: cart.vase.id, price: cart.vase.price, priceView: cart.vase.price, sku: cart.vase.variantShopifyProd.sku, tags:  cart.vase.parentProd.tags, title: cart.vase.parentProd.title, titleView: cart.vase.parentProd.title}});
  }
  store.dispatch("cartItem/RECIPIENT_ADD", cartLineItem.recipient);
  store.dispatch("cartItem/DELIVERY_WINDOW_ADD", cartLineItem.deliveryWindow);
  store.dispatch("cartItem/CONTACT_DETAIL_ADD", cartLineItem.contactDetails);
  store.dispatch("cartItem/CARD_ADD", cartLineItem.card);
  store.dispatch("cartItem/CARD_MESSAGES_ADD", cartLineItem.cardMessages);
  store.dispatch("cartItem/UPDATE_DELIVERY_DATE", cartLineItem.deliveryWindow.deliveryDate);
  store.dispatch("cartItem/UPDATE_DELIVERY_TOWN", cartLineItem.deliveryTown);
  store.dispatch("cartItem/SET_RELATIONSHIP", cartLineItem.relationship);
  store.dispatch("cartItem/SET_OCCASION", cartLineItem.occasion);

  store.dispatch("lineItems/ADD_CART_ITEM", {... store.state.cartItem})
  store.dispatch("cartItem/CART_ITEM_FLUSH");

  sessionStorage.setItem('deliveryTown', JSON.stringify(cartLineItem.deliveryTown));
  sessionStorage.setItem('deliveryDate', cartLineItem.deliveryDate);


}